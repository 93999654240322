exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-complaints-tsx": () => import("./../../../src/pages/complaints.tsx" /* webpackChunkName: "component---src-pages-complaints-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-a-quote-tsx": () => import("./../../../src/pages/get-a-quote.tsx" /* webpackChunkName: "component---src-pages-get-a-quote-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-make-a-claim-tsx": () => import("./../../../src/pages/make-a-claim.tsx" /* webpackChunkName: "component---src-pages-make-a-claim-tsx" */),
  "component---src-pages-policy-cancellation-tsx": () => import("./../../../src/pages/policy-cancellation.tsx" /* webpackChunkName: "component---src-pages-policy-cancellation-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-types-of-cover-tsx": () => import("./../../../src/pages/types-of-cover.tsx" /* webpackChunkName: "component---src-pages-types-of-cover-tsx" */),
  "component---src-pages-visa-info-uk-tsx": () => import("./../../../src/pages/visa-info-uk.tsx" /* webpackChunkName: "component---src-pages-visa-info-uk-tsx" */),
  "component---src-pages-visa-info-usa-tsx": () => import("./../../../src/pages/visa-info-usa.tsx" /* webpackChunkName: "component---src-pages-visa-info-usa-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */)
}

